<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <e-button
          size="m"
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            class="ml-3"
            :class="{ rotate: visibleParams }"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </e-button>
      </div>
      <div class="b-action-buttons d-flex">
        <e-button
          v-if="!document?.fixed"
          size="m"
          variant="outline-primary"
          class="cursor"
          @click="back"
        >
          Сохранить как черновик
        </e-button>
        <e-button
          size="m"
          class="ml-2"
          variant="primary"
          :loading="loading"
          :disabled="loading"
          @click="fixed"
        >
          <template v-if="!document?.fixed">Провести</template>
          <template v-if="document?.fixed">Редактировать</template>
        </e-button>
      </div>
    </div>
    <e-collapse
      id="collapse-filters"
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <invoice-filters
          ref="header_filters_incoming"
          :fixed="document?.fixed"
          :sender_err="senderErr"
          :recipient_err="recipientErr"
          :filter_params="document"
          @change="change"
        />
      </template>
    </e-collapse>
  </div>
</template>

<script>
  import { InvoiceHeadModel } from '@/views/operational-processes/models/invoice-head.model'
  import InvoiceFilters from '@/views/operational-processes/components/invoice/InvoiceFilters'
  export default {
    name: 'InvoiceHead',
    components: { InvoiceFilters },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      document: {
        type: InvoiceHeadModel,
        default: () => new InvoiceHeadModel()
      }
    },
    data() {
      return {
        visibleParams: false,
        senderErr: null,
        recipientErr: null
      }
    },
    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    methods: {
      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },
      fixed() {
        console.log(this.document)
        if (!this.document.sender_entity?.id) {
          this.senderErr = true
          if (!this.document.recipient_entity?.id) {
            this.recipientErr = true
            this.$noty.error('Вы не выбрали отправителя и получателя')
            return
          }
          this.$noty.error('Вы не выбрали отправителя')
          return
        }
        if (!this.document.recipient_entity?.id) {
          this.recipientErr = true
          this.$noty.error('Вы не выбрали получателя')
          return
        }
        if (!this.document.specifications?.total) {
          this.$noty.error('Вы не добавили товар')
          return
        }
        this.$emit('fixed', !this.document.fixed)
      },
      change(val) {
        this.document.setComment(val.comment)
        if (val.sender_entity) {
          this.senderErr = null
          this.document.setSenderEntity(val.sender_entity)
        }
        if (val.recipient_entity) {
          this.document.setRecipientEntity(val.recipient_entity)
          this.recipientErr = null
        }
        this.$emit('update-head', this.document)
      },
      back() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .params-body {
      .form {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 0;
        max-width: 1440px;

        ::v-deep .form-group {
          min-width: 150px;
          margin-bottom: 0;
        }

        .col-lg-4 {
          margin-bottom: 16px !important;
        }

        // @media (max-width: 1200px) {}
      }

      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      #collapse-extraparams {
        textarea {
          overflow: hidden;
          resize: none;
        }
      }

      @media screen and (max-width: 1290px) and (min-width: 1200px) {
        .col-xl-2 {
          flex: 0 0 33.33333333%;
          max-width: 33.33333333%;
        }
      }
    }

    .elm-calendar input {
      height: 31px !important;
    }
  }

  .rotate {
    transform: rotate(360deg) !important;
  }
</style>
