<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  disabled
                  :clearable="false"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Отправитель"
              label-for="storage"
              :class="{ error: sender_err }"
            >
              <template v-if="fixed">
                {{ filter_params?.sender_entity?.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.sender_entity.id"
                  filterable
                  transfer
                  @on-change="saveSenderEntity"
                >
                  <i-option
                    v-for="item in supplier_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Получатель"
              label-for="storage"
              :class="{ error: recipient_err }"
            >
              <template v-if="fixed">
                {{ filter_params?.recipient_entity?.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.recipient_entity.id"
                  filterable
                  transfer
                  @on-change="saveRecipientEntity"
                >
                  <i-option
                    v-for="item in supplier_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    :disabled="fixed"
                    @input="updateHead"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { InvoiceHeadModel } from '@/views/operational-processes/models/invoice-head.model'

  export default {
    name: 'InvoiceFilters',
    apollo: {
      Entities: {
        query: require('../../gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Entities || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      filter_params: {
        type: InvoiceHeadModel,
        default: () => new InvoiceHeadModel()
      },
      fixed: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      },
      sender_err: null,
      recipient_err: null
    },
    data() {
      return {
        inv_head: {
          date: new Date(),
          storages: [],
          comment: '',
          sender_entity: null,
          recipient_entity: null
        },
        supplier_list: [],
        date: this.filter_params.date
      }
    },
    methods: {
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      updateHead() {
        this.$emit('change', this.inv_head)
      },
      changeStorage(val) {
        this.inv_head.storage = val
        this.$emit('change', this.inv_head)
      },
      saveSenderEntity(value) {
        this.inv_head.sender_entity = value
        this.$emit('change', this.inv_head)
      },
      saveRecipientEntity(value) {
        this.inv_head.recipient_entity = value
        this.$emit('change', this.inv_head)
      },
      setDate() {
        this.$emit('change', this.date)
      }
    }
  }
</script>

<style scoped>
  .params-body {
    padding-top: 16px;
  }
</style>
